export * from ".";

const publicRoutes = ["Join Team", "Signup", "Login", "Forgot Password", "Reset Password"];

export async function redirectIfAuthenticated(to, from, next) {
	try {
		const token = localStorage.getItem('token')
		if (token) {
      if (publicRoutes.includes(to.name)) return next("/dashboard");
			return next();
		} else return next();
	} catch (error) {
		let redirectTo = undefined;
		if (publicRoutes.includes(to.name)) {
			return next();
		} else {
			redirectTo = {
				path: "/login",
			};
		}
		return next(redirectTo);
	}
}
