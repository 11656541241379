import { AUTH_ROUTES, DASHBOARD_ROUTES } from "../constants/routes";
import { redirectIfAuthenticated } from "../middlewares";


function loadView(view) {
	return () => import(/* webpackChunkName: "about" */ `../views/${view}`);
}

export const routes = [
	{ path: '/compliance', redirect: '/compliance/company' },
	{ path: '/origination', redirect: '/origination/pending' },
	{ path: '/settings', redirect: '/settings/profile' },
  {
		path: '*',
		name: '404',
		component: loadView('NotFound.vue')
	},
//   {
// 		path: AUTH_ROUTES.SIGNUP,
// 		name: "Signup",
// 		component: loadView("auth/Signup.vue"),
// 		beforeEnter: redirectIfAuthenticated,
// 	},
//   {
// 		path: AUTH_ROUTES.LOGIN,
// 		name: "Login",
// 		component: loadView("auth/Login.vue"),
// 		beforeEnter: redirectIfAuthenticated,
// 	},
  {
		path: AUTH_ROUTES.JOIN_TEAM,
		name: "Join Team",
		component: loadView("auth/JoinTeam.vue"),
		// beforeEnter: redirectIfAuthenticated,
	},
  {
		path: AUTH_ROUTES.FORGOT_PASSWORD,
		name: "Forgot Password",
		component: loadView("auth/ForgotPassword.vue"),
		beforeEnter: redirectIfAuthenticated,
	},
  {
		path: AUTH_ROUTES.RESET_PASSWORD,
		name: "Reset Password",
		component: loadView("auth/ResetPassword.vue"),
		beforeEnter: redirectIfAuthenticated,
	},
	{
		path: DASHBOARD_ROUTES.GET_STARTED,
		name: 'Get Started',
		component: loadView('dashboard/GetStarted.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.COMPLIANCE,
		name: 'Compliance',
		component: loadView('dashboard/Compliance.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.HOME,
		name: 'Home',
		component: loadView('dashboard/Home.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.ORIGINATION.BASE,
		name: 'Origination',
		component: loadView('dashboard/origination/Base.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.PRODUCT.OVERVIEW.BASE,
		name: 'Products',
		component: loadView('dashboard/product/Overview/Base.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.PRODUCT.RULES.BASE,
		name: 'Products',
		component: loadView('dashboard/product/Rules/Base.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.PRODUCT.RULES.ADDPRODUCTRULE,
		name: 'Products',
		component: loadView('dashboard/product/Rules/AddProductRule.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.PRODUCT.MANAGEMENT.BASE,
		name: 'Products',
		component: loadView('dashboard/product/Management/Base.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.PRODUCT.MANAGEMENT.ADDPRODUCT,
		name: 'Products',
		component: loadView('dashboard/product/Management/AddProduct.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.COLLECTION.OVERVIEW.BASE,
		name: 'Collection',
		component: loadView('dashboard/collection/Overview/Base.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.COLLECTION.CUSTOMERS.BASE,
		name: 'Collection',
		component: loadView('dashboard/collection/Customers/Base.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.COLLECTION.REPORTS.BASE,
		name: 'Collection',
		component: loadView('dashboard/collection/Reports/Base.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.COLLECTION.CUSTOMERS.SINGLE,
		name: 'Collection',
		component: loadView('dashboard/collection/Customers/AddSingleCustomer.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.COLLECTION.CUSTOMERS.MULTIPLE,
		name: 'Collection',
		component: loadView('dashboard/collection/Customers/AddMultipleCustomer.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.ORIGINATION.BORROWER,
		name: 'Borrower Details',
		component: loadView('dashboard/origination/Borrower.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.REPORTS,
		name: 'Reports',
		component: loadView('dashboard/Home.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.BILLINGS.BASE,
		name: 'Billings',
		component: loadView('dashboard/billings/Base.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.BILLINGS.PRICING,
		name: 'Pricing',
		component: loadView('dashboard/billings/Pricing.vue'),
		beforeEnter: (to, from, next) => {
			if(from.name !== 'Billings') return next('/billings')
			else return next()
		},
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.ACTIVITY,
		name: 'Activity',
		component: loadView('dashboard/Home.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.SETTINGS,
		name: 'Settings',
		component: loadView('dashboard/Settings.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.MANAGE_ROLES,
		name: 'Manage Roles',
		component: loadView('dashboard/Settings.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.SUPPORT,
		name: 'Support',
		component: loadView('dashboard/Support.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.VERIFICATION.BASE,
		name: 'Verification',
		component: loadView('dashboard/verification/Base.vue'),
		meta: {
			requiresAuth: true,
		}
	},
	{
		path: DASHBOARD_ROUTES.VERIFICATION.BORROWER,
		name: 'Verification Details',
		component: loadView('dashboard/verification/Borrower.vue'),
		meta: {
			requiresAuth: true,
		}
	},

	// New Lenders Landing Page Routing

	{
		path: '/',
		name: 'Landing',
		component: () => import('../pages/Home.vue'),
		meta: { title: "Home Landing" }
	},
	{
		path: '/origination-landing',
		name: 'Origination Landing',
		component: () => import('../pages/Origination.vue'),
		meta: { title: "Origination Landing" }
	},
	{
		path: '/disbursement-landing',
		name: 'Disbursement Landing',
		component: () => import('../pages/Disbursement.vue'),
		meta: { title: "Disbursement Landing" }
	},
	{
		path: '/collection-landing',
		name: 'Collection Landing',
		component: () => import('../pages/Collection.vue'),
		meta: { title: "Collection Landing" }
	},
	{
		path: '/privacy',
		name: 'Data Privacy',
		component: () => import('../pages/Privacy.vue'),
		meta: { title: "Data Privacy" }
	},
	{
		path: '/about-us',
		name: 'About Us',
		component: () => import('../pages/AboutUs.vue'),
		meta: { title: "About Us" }
	},
	{
		path: '/terms-of-use',
		name: 'Terms of Use',
		component: () => import('../pages/TermsOfUse.vue'),
		meta: { title: "Terms of Use" }
	},
	{
		path: '/data-protection',
		name: 'Data Protection',
		component: () => import('../pages/DataProtection.vue'),
		meta: { title: "Data Protection" }
	},
]