<template>
  <div class="bg-drawer pt-4 pb-12 w-fit-content fixed left-0 top-0 bottom-0 flex flex-col overflow-y-scroll drawer-scroll">
    <div class="p-2 rounded-lg flex items-center justify-between mx-3 xl:mx-6">
      <div class="flex items-center">
        <img v-if="getUser.lenders[0].logo" :src="getUser.lenders[0].logo" alt="" class="w-10 h-10 rounded-lg object-cover border border.gray.500 border-drawer-img">
        <div v-else class="w-10 h-10 rounded-lg bg-success-bg text-success text-4 flex items-center justify-center font-family-semibold border border-gray-500">{{ getUser.lenders[0].name.charAt(0).toUpperCase() }}</div>
        <div v-if="getUser" :class="showFullLink ? '' : 'hidden'" class="xl:block ml-2">
          <p class="text-white font-family-semibold text-xs">{{ getUser.lenders[0].name.slice(0, 20) }}</p>
          <span class="text-drawer-text text-xs">{{ `${getUser.user.firstName} ${getUser.user.lastName}`.slice(0, 20) }}</span>
        </div>
      </div>
      <svg class="ml-2" width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.00025 6.50025C4.74425 6.50025 4.48825 6.40225 4.29325 6.20725L0.29325 2.20725C-0.09775 1.81625 -0.09775 1.18425 0.29325 0.79325C0.68425 0.40225 1.31625 0.40225 1.70725 0.79325L5.01225 4.09825L8.30525 0.91825C8.70425 0.53525 9.33525 0.54625 9.71925 0.94325C10.1032 1.34025 10.0922 1.97425 9.69525 2.35725L5.69525 6.21925C5.50025 6.40725 5.25025 6.50025 5.00025 6.50025Z" fill="#A4A6B3"/>
      </svg>
    </div>

    <div @click="showFullDrawer" :class="showFullLink ? 'mr-auto ml-4 transform rotate-180' : 'mx-auto'" class="xl:hidden mt-10 bg-gray-300 p-4 min-w-10 min-h-10 flex items-center justify-center rounded-full">
      <svg width="9" height="10" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.40012 7.00082C8.40012 7.35921 8.26293 7.7176 7.98993 7.99059L2.39009 13.5904C1.8427 14.1378 0.957924 14.1378 0.410539 13.5904C-0.136846 13.0431 -0.136846 12.1583 0.410539 11.6109L5.03741 6.98402L0.585534 2.37394C0.0493481 1.81536 0.0647476 0.931982 0.620533 0.394397C1.17632 -0.143189 2.06389 -0.127789 2.60008 0.427996L8.00673 6.02784C8.26993 6.30084 8.40012 6.65083 8.40012 7.00082" fill="#A4A6B3"/>
      </svg>
    </div>
    
    <div :class="showFullLink ? 'mx-3' : 'mx-auto'" class="mt-10 pb-4 xl:mx-6 w-fit-content">
      <router-link to="/get-started" :class="path === '/get-started' ? 'bg-drawer-active' : ''" class="flex items-center p-4.5 rounded-lg xl:w-52 hover:bg-drawer-active my-1">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.39258 10.6069L6.63544 6.63544L10.6069 5.39258L9.36401 9.36401L5.39258 10.6069Z" :stroke="path === '/get-started' ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
          <circle cx="8" cy="8" r="7.5" :stroke="path === '/get-started' ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [path === '/get-started' ? 'text-white' : 'text-drawer-text']]">Get Started</p>
      </router-link>

      <router-link to="/compliance/company" :class="compliancePaths.includes(path) ? 'bg-drawer-active' : ''" class="flex items-center p-4.5 rounded-lg xl:w-52 hover:bg-drawer-active my-1">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7483 1H5.25081C2.63805 1 1 2.84995 1 5.46789V12.5321C1 15.1501 2.63027 17 5.25081 17H12.7475C15.3689 17 17 15.1501 17 12.5321V5.46789C17 2.84995 15.3689 1 12.7483 1Z" :stroke="compliancePaths.includes(path) ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6 9L8.00056 12L12 6" :stroke="compliancePaths.includes(path) ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [compliancePaths.includes(path) ? 'text-white' : 'text-drawer-text']]">Compliance</p>
      </router-link>

      <router-link to="/dashboard" :class="[path === '/dashboard' ? 'bg-drawer-active' : '', tour.start && tour.stage === 1 ? 'bg-tour-active shadow-tour' : '']" class="flex items-center p-4.5 rounded-lg xl:w-52 hover:bg-drawer-active my-1">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.90532 14.4634V12.2037C5.90531 11.629 6.37388 11.1619 6.95444 11.1581H9.08102C9.66435 11.1581 10.1372 11.6262 10.1372 12.2037V14.4704C10.1371 14.9584 10.5305 15.3573 11.0233 15.3687H12.441C13.8543 15.3687 15 15.3687 15 13.9695V6.40709C14.9925 5.85666 14.7314 5.33978 14.2912 5.00355L9.44254 1.1368C8.59312 0.463516 7.38563 0.463516 6.53621 1.1368L1.70886 5.01057C1.26693 5.34544 1.00544 5.86318 1 6.41411V13.9695C1 15.3687 2.1457 15.3687 3.55899 15.3687H4.97671C5.48174 15.3687 5.89114 14.9634 5.89114 14.4634" :stroke="path === '/' || tour.start && tour.stage === 1 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [path === '/dashboard' || tour.start && tour.stage === 1 ? 'text-white' : 'text-drawer-text']]">Home</p>
      </router-link>
    </div>

    <div class="w-full h-full border-t flex flex-col border-drawer-border min-h-50 overflow-y-scroll drawer-scroll">
      <div :class="showFullLink ? 'mx-3' : 'mx-auto'" class="pt-4 pb-4 xl:mx-6 w-fit-content">
        <router-link to="/origination/pending?page=1&limit=10" :class="[originationPaths.includes(path) || originationDetailsPaths.includes(path) ? 'bg-drawer-active' : '', tour.start && tour.stage === 2 ? 'bg-tour-active shadow-tour' : '']" class="flex items-center p-4.5 rounded-lg xl:w-52 hover:bg-drawer-active my-1">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9366 7.12727C16.404 3.84944 13.6199 1.4109 10.3005 1.31491C10.1426 1.30872 9.98883 1.36555 9.87294 1.47286C9.75704 1.58017 9.68857 1.72916 9.68262 1.88699V1.88699V1.94039L10.0564 7.53154C10.0809 7.90792 10.4045 8.19402 10.781 8.17227L16.3874 7.79851C16.5454 7.78683 16.6922 7.71263 16.7953 7.59236C16.8984 7.47209 16.9492 7.31568 16.9366 7.15778V7.12727Z" :stroke="originationPaths.includes(path) || originationDetailsPaths.includes(path) || tour.start && tour.stage === 2 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.41784 4.6408C6.77447 4.55831 7.13942 4.74079 7.28741 5.07559C7.32617 5.15433 7.34948 5.23978 7.35606 5.3273C7.43234 6.41044 7.59252 8.78268 7.68405 10.0641C7.69968 10.295 7.80666 10.5101 7.98131 10.6618C8.15596 10.8136 8.38388 10.8895 8.61464 10.8727V10.8727L13.321 10.5828C13.5311 10.5702 13.7371 10.645 13.8902 10.7894C14.0433 10.9338 14.1299 11.1351 14.1295 11.3456V11.3456C13.9388 14.1876 11.8969 16.5634 9.11593 17.1793C6.33493 17.7951 3.4809 16.5035 2.10816 14.0077C1.69836 13.2894 1.43885 12.4953 1.34538 11.6736C1.30542 11.4214 1.29009 11.166 1.29961 10.9108C1.30771 7.87583 3.4392 5.26077 6.41022 4.6408" :stroke="originationPaths.includes(path) || originationDetailsPaths.includes(path) || tour.start && tour.stage === 2 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [originationPaths.includes(path) || originationDetailsPaths.includes(path) || tour.start && tour.stage === 2 ? 'text-white' : 'text-drawer-text']]">Origination</p>
        </router-link>

         <div :class="[productPaths.includes(path) && openproductDrawer === true ? 'bg-drawer-active' : '', tour.start && tour.stage === 3 ? 'bg-tour-active shadow-tour' : '']" class=" rounded-lg xl:w-52">
          <span @click="productDrawer" class="flex items-center   rounded-lg xl:w-52 hover:bg-drawer-active my-1 cursor-pointer" :class="[productPaths.includes(path) && openproductDrawer === true ? 'pb-2.5 pt-4.5 px-4.5' : 'p-4.5']">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" fill="#E5E5E5"/>
            <rect width="1440" height="899" transform="translate(-41 -371)" :fill="productPaths.includes(path) ? 'white' : '#A4A6B3'"/>
            <rect width="255" height="899" transform="translate(-41 -371)" fill="#363740"/>
            <path d="M8.99979 10.0814C8.89146 10.0814 8.78312 10.0564 8.68312 9.99811L1.3248 5.73979C1.0248 5.56479 0.924779 5.18144 1.09978 4.88144C1.27478 4.58144 1.64978 4.48142 1.95811 4.65642L8.99979 8.73143L15.9998 4.68144C16.2998 4.50644 16.6831 4.61479 16.8581 4.90646C17.0331 5.20646 16.9248 5.58977 16.6331 5.76477L9.3248 9.99811C9.21647 10.0481 9.10812 10.0814 8.99979 10.0814Z" :fill="productPaths.includes(path) ? 'white' : '#A4A6B3'"/>
            <path d="M9 17.6326C8.65833 17.6326 8.375 17.3492 8.375 17.0076V9.44922C8.375 9.10755 8.65833 8.82422 9 8.82422C9.34167 8.82422 9.625 9.10755 9.625 9.44922V17.0076C9.625 17.3492 9.34167 17.6326 9 17.6326Z" :fill="productPaths.includes(path) ? 'white' : '#A4A6B3'"/>
            <path d="M9.00002 17.9596C8.26669 17.9596 7.54168 17.8013 6.96668 17.4846L2.51669 15.0096C1.30835 14.343 0.366699 12.7346 0.366699 11.3513V6.64295C0.366699 5.25962 1.30835 3.65965 2.51669 2.98465L6.96668 0.517969C8.10835 -0.115365 9.89169 -0.115365 11.0334 0.517969L15.4834 2.99294C16.6917 3.65961 17.6333 5.26796 17.6333 6.65129V11.3596C17.6333 12.743 16.6917 14.3429 15.4834 15.0179L11.0334 17.4846C10.4584 17.8013 9.73335 17.9596 9.00002 17.9596ZM9.00002 1.29296C8.47502 1.29296 7.95836 1.4013 7.57503 1.60963L3.12503 4.08461C2.32503 4.53461 1.6167 5.72629 1.6167 6.65129V11.3596C1.6167 12.2763 2.32503 13.4763 3.12503 13.9263L7.57503 16.4013C8.33336 16.8263 9.66668 16.8263 10.425 16.4013L14.875 13.9263C15.675 13.4763 16.3833 12.2846 16.3833 11.3596V6.65129C16.3833 5.73462 15.675 4.53461 14.875 4.08461L10.425 1.60963C10.0417 1.4013 9.52502 1.29296 9.00002 1.29296Z" :fill="productPaths.includes(path) ? 'white' : '#A4A6B3'"/>
            <path d="M13.167 10.6575C12.8253 10.6575 12.542 10.3741 12.542 10.0325V7.34917L4.94198 2.96583C4.64198 2.79083 4.54197 2.40748 4.71697 2.11581C4.89197 1.81581 5.26698 1.71579 5.56698 1.89079L13.4753 6.4575C13.667 6.56583 13.792 6.77413 13.792 6.99913V10.0492C13.792 10.3742 13.5086 10.6575 13.167 10.6575Z" :fill="productPaths.includes(path) ? 'white' : '#A4A6B3'"/>
          </svg>
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [productPaths.includes(path) && openproductDrawer === true  || tour.start && tour.stage === 3 ? 'text-white' : 'text-drawer-text']]">Product</p>
        </span>
        <span class="bg-[white]" v-if="openproductDrawer && productPaths.includes(path)">
          <router-link to="/product/overview?filter=last7days&product=All Product&id=allProduct"  class="flex items-center px-10 py-1.5 rounded-lg xl:w-52">
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [productOverviewPaths.includes(path) || tour.start && tour.stage === 2 ? 'text-white' : 'text-drawer-text']]">Overview</p>
        </router-link>
        <router-link to="/product/management?page=1&limit=10"  class="flex items-center px-10 py-1.5 rounded-lg xl:w-52">
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [productManagementPaths.includes(path) || tour.start && tour.stage === 2 ? 'text-white' : 'text-drawer-text']]">Management</p>
        </router-link>
        <router-link to="/product/rules?tab=rule-details&page=1&limit=10"  class="flex items-center px-10 py-1.5 rounded-lg xl:w-52">
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [productRulesPaths.includes(path) || tour.start && tour.stage === 2 ? 'text-white' : 'text-drawer-text']]">Rules</p>
        </router-link>
        </span>
        </div>
        <div :class="[collectionPaths.includes(path) && openCollectionDrawer === true ? 'bg-drawer-active' : '', tour.start && tour.stage === 3 ? 'bg-tour-active shadow-tour' : '']" class=" rounded-lg xl:w-52">
          <span @click="collectionDrawer" class="flex items-center   rounded-lg xl:w-52 hover:bg-drawer-active my-1 cursor-pointer" :class="[collectionPaths.includes(path) && openCollectionDrawer === true ? 'pb-2.5 pt-4.5 px-4.5' : 'p-4.5']">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1665 17.2904H9.99984C9.65817 17.2904 9.37484 17.007 9.37484 16.6654C9.37484 16.3237 9.65817 16.0404 9.99984 16.0404H14.1665C16.5498 16.0404 17.7082 14.882 17.7082 12.4987V7.4987C17.7082 5.11536 16.5498 3.95703 14.1665 3.95703H5.83317C3.44984 3.95703 2.2915 5.11536 2.2915 7.4987V9.16536C2.2915 9.50703 2.00817 9.79036 1.6665 9.79036C1.32484 9.79036 1.0415 9.50703 1.0415 9.16536V7.4987C1.0415 4.45703 2.7915 2.70703 5.83317 2.70703H14.1665C17.2082 2.70703 18.9582 4.45703 18.9582 7.4987V12.4987C18.9582 15.5404 17.2082 17.2904 14.1665 17.2904Z" :fill="collectionPaths.includes(path) ? 'white' : '#A4A6B3'"/>
            <path d="M9.99984 12.7096C8.50817 12.7096 7.2915 11.493 7.2915 10.0013C7.2915 8.50964 8.50817 7.29297 9.99984 7.29297C11.4915 7.29297 12.7082 8.50964 12.7082 10.0013C12.7082 11.493 11.4915 12.7096 9.99984 12.7096ZM9.99984 8.54297C9.19984 8.54297 8.5415 9.2013 8.5415 10.0013C8.5415 10.8013 9.19984 11.4596 9.99984 11.4596C10.7998 11.4596 11.4582 10.8013 11.4582 10.0013C11.4582 9.2013 10.7998 8.54297 9.99984 8.54297Z" :fill="collectionPaths.includes(path) ? 'white' : '#A4A6B3'"/>
            <path d="M15.4165 12.7096C15.0748 12.7096 14.7915 12.4263 14.7915 12.0846V7.91797C14.7915 7.5763 15.0748 7.29297 15.4165 7.29297C15.7582 7.29297 16.0415 7.5763 16.0415 7.91797V12.0846C16.0415 12.4263 15.7582 12.7096 15.4165 12.7096Z" :fill="collectionPaths.includes(path) ? 'white' : '#A4A6B3'"/>
            <path d="M7.08317 15.5763C6.7415 15.5763 6.45817 15.293 6.45817 14.9513V13.8846C6.45817 13.7013 6.30819 13.543 6.11653 13.543H1.6665C1.32484 13.543 1.0415 13.2596 1.0415 12.918C1.0415 12.5763 1.32484 12.293 1.6665 12.293H6.11653C6.99153 12.293 7.70817 13.0096 7.70817 13.8846V14.9513C7.70817 15.293 7.42484 15.5763 7.08317 15.5763Z" :fill="collectionPaths.includes(path) ? 'white' : '#A4A6B3'"/>
            <path d="M2.68353 14.5586C2.5252 14.5586 2.36689 14.5003 2.24189 14.3753L1.2252 13.3586C0.983529 13.117 0.983529 12.7169 1.2252 12.4752L2.24189 11.4586C2.48356 11.2169 2.88355 11.2169 3.12522 11.4586C3.36689 11.7003 3.36689 12.1003 3.12522 12.3419L2.55022 12.9169L3.12522 13.4919C3.36689 13.7336 3.36689 14.1336 3.12522 14.3753C3.00022 14.5003 2.84186 14.5586 2.68353 14.5586Z" :fill="collectionPaths.includes(path) ? 'white' : '#A4A6B3'"/>
            <path d="M7.08317 17.9396H2.63315C1.75815 17.9396 1.0415 17.2229 1.0415 16.3479V15.2812C1.0415 14.9396 1.32484 14.6562 1.6665 14.6562C2.00817 14.6562 2.2915 14.9396 2.2915 15.2812V16.3479C2.2915 16.5312 2.44148 16.6896 2.63315 16.6896H7.08317C7.42484 16.6896 7.70817 16.9729 7.70817 17.3146C7.70817 17.6563 7.42484 17.9396 7.08317 17.9396Z" :fill="collectionPaths.includes(path) ? 'white' : '#A4A6B3'"/>
            <path d="M6.0663 18.957C5.90797 18.957 5.74961 18.8987 5.62461 18.7737C5.38294 18.532 5.38294 18.132 5.62461 17.8903L6.19961 17.3154L5.62461 16.7404C5.38294 16.4987 5.38294 16.0987 5.62461 15.857C5.86628 15.6154 6.26627 15.6154 6.50794 15.857L7.52463 16.8737C7.7663 17.1153 7.7663 17.5153 7.52463 17.757L6.50794 18.7737C6.39127 18.8987 6.22463 18.957 6.0663 18.957Z" :fill="collectionPaths.includes(path) ? 'white' : '#A4A6B3'"/>
          </svg>
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [collectionPaths.includes(path) && openCollectionDrawer === true  || tour.start && tour.stage === 3 ? 'text-white' : 'text-drawer-text']]">Collection</p>
        </span>
        <span class="bg-[white]" v-if="openCollectionDrawer && collectionPaths.includes(path)">
          <router-link to="/collection/overview?page=1&limit=10"  class="flex items-center px-10 py-1.5 rounded-lg xl:w-52">
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [collectionOverviewPaths.includes(path) || tour.start && tour.stage === 2 ? 'text-white' : 'text-drawer-text']]">Overview</p>
        </router-link>
        <router-link to="/collection/customers?page=1&limit=10"  class="flex items-center px-10 py-1.5 rounded-lg xl:w-52">
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [collectionCustomersPaths.includes(path) || tour.start && tour.stage === 2 ? 'text-white' : 'text-drawer-text']]">Customers</p>
        </router-link>
        <router-link to="/collection/reports"  class="flex items-center px-10 py-1.5 rounded-lg xl:w-52">
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [collectionReportsPaths.includes(path) || tour.start && tour.stage === 2 ? 'text-white' : 'text-drawer-text']]">Reports</p>
        </router-link>
        </span>
        </div>

        <!-- <router-link to="/reports" :class="[path === '/reports' ? 'bg-drawer-active' : '', tour.start && tour.stage === 3 ? 'bg-tour-active shadow-tour' : '']" class="flex items-center p-4.5 rounded-lg xl:w-52 hover:bg-drawer-active my-1">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.14243 7.50195V13.2187" :stroke="path === '/reports' || tour.start && tour.stage === 3 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.03159 4.7666V13.2189" :stroke="path === '/reports' || tour.start && tour.stage === 3 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.8568 10.5225V13.2183" :stroke="path === '/reports' || tour.start && tour.stage === 3 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9046 0.666992H5.09508C2.37285 0.666992 0.666504 2.59373 0.666504 5.32129V12.6794C0.666504 15.4069 2.36492 17.3337 5.09508 17.3337H12.9046C15.6348 17.3337 17.3332 15.4069 17.3332 12.6794V5.32129C17.3332 2.59373 15.6348 0.666992 12.9046 0.666992Z" :stroke="path === '/reports' || tour.start && tour.stage === 3 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [path === '/reports' || tour.start && tour.stage === 3 ? 'text-white' : 'text-drawer-text']]">Reports</p>
        </router-link> -->

        <router-link to="/billings" :class="[['/billings', '/billings/pricing'].includes(path) ? 'bg-drawer-active' : '', tour.start && tour.stage === 4 ? 'bg-tour-active shadow-tour' : '']" class="flex items-center p-4.5 rounded-lg xl:w-52 hover:bg-drawer-active my-1">
          <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.0321 9.9962H13.6585C12.4198 9.99544 11.4158 8.99217 11.415 7.75347C11.415 6.51477 12.4198 5.5115 13.6585 5.51074H17.0321" :stroke="['/billings', '/billings/pricing'].includes(path) || tour.start && tour.stage === 4 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.0404 7.702H13.7806" :stroke="['/billings', '/billings/pricing'].includes(path) || tour.start && tour.stage === 4 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.45606 0.5H12.6589C15.074 0.5 17.032 2.45792 17.032 4.87305V10.8539C17.032 13.269 15.074 15.227 12.6589 15.227H5.45606C3.04093 15.227 1.08301 13.269 1.08301 10.8539V4.87305C1.08301 2.45792 3.04093 0.5 5.45606 0.5Z" :stroke="['/billings', '/billings/pricing'].includes(path) || tour.start && tour.stage === 4 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.86279 4.28208H9.36192" :stroke="['/billings', '/billings/pricing'].includes(path) || tour.start && tour.stage === 4 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [['/billings', '/billings/pricing'].includes(path) || tour.start && tour.stage === 4 ? 'text-white' : 'text-drawer-text']]">Billings</p>
        </router-link>

        <router-link to="/verification/request" :class="verificationPaths.includes(path) || $route.name === 'Verification Details' ? 'bg-drawer-active' : ''" class="flex items-center p-4.5 rounded-lg xl:w-52 hover:bg-drawer-active my-1">
          <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.07192 17.3327C7.96872 17.3327 7.86553 17.309 7.77241 17.2608L4.7715 15.7074C3.91997 15.2661 3.25384 14.7709 2.7337 14.194C1.59524 12.9323 0.961839 11.3127 0.951771 9.63261L0.916536 4.1029C0.912341 3.4647 1.32426 2.89187 1.94005 2.67614L7.45026 0.755006C7.77745 0.638152 8.14239 0.636518 8.47545 0.749286L14.0066 2.60505C14.6258 2.81179 15.0444 3.38053 15.0478 4.01792L15.083 9.55171C15.0939 11.2293 14.4823 12.8555 13.3615 14.1311C12.8472 14.7161 12.187 15.2187 11.3438 15.6681L8.36974 17.2567C8.27746 17.3065 8.17511 17.3319 8.07192 17.3327" :stroke="verificationPaths.includes(path) || $route.name === 'Verification Details' ? 'white' : '#A4A6B3'"/>
            <path d="M5.5 9L7.16713 11.5L10.5 6.5" :stroke="verificationPaths.includes(path) || $route.name === 'Verification Details' ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [verificationPaths.includes(path) || $route.name === 'Verification Details' ? 'text-white' : 'text-drawer-text']]">Verification</p>
        </router-link>
      </div>

      <div :class="[showFullLink ? 'mx-3' : 'mx-auto', tour.start && tour.stage === 3 ? 'bg-tour-active shadow-tour rounded-lg' : '']" class="mt-auto w-fit-content xl:mx-6 mb-16">
        <!-- <router-link to="/activity" :class="path === '/activity' ? 'bg-drawer-active' : ''" class="flex items-center p-4.5 rounded-lg xl:w-52 hover:bg-drawer-active my-1">
          <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6344 7.04418C11.6344 8.49918 10.4544 9.67835 8.9994 9.67835C7.5444 9.67835 6.36523 8.49918 6.36523 7.04418C6.36523 5.58835 7.5444 4.40918 8.9994 4.40918C10.4544 4.40918 11.6344 5.58835 11.6344 7.04418Z" :stroke="path === '/activity' || tour.start && tour.stage === 5 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99817 13.129C12.1715 13.129 15.074 10.8473 16.7082 7.04398C15.074 3.24065 12.1715 0.958984 8.99817 0.958984H9.0015C5.82817 0.958984 2.92567 3.24065 1.2915 7.04398C2.92567 10.8473 5.82817 13.129 9.0015 13.129H8.99817Z" :stroke="path === '/activity' || tour.start && tour.stage === 5 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [path === '/activity' || tour.start && tour.stage === 5 ? 'text-white' : 'text-drawer-text']]">Activity log</p>
        </router-link> -->

        <router-link to="/settings/profile?tab=personal" :class="settingsPath.includes(path) ? 'bg-drawer-active' : ''" class="flex items-center p-4.5 rounded-lg xl:w-52 hover:bg-drawer-active my-1">
          <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3392 5.35305L14.8205 4.45298C14.3817 3.69138 13.4092 3.42864 12.6466 3.86564V3.86564C12.2835 4.07949 11.8504 4.14017 11.4425 4.03428C11.0347 3.92839 10.6858 3.66464 10.4727 3.30118C10.3355 3.07016 10.2619 2.80704 10.2591 2.53841V2.53841C10.2714 2.10773 10.109 1.69038 9.80864 1.38143C9.50832 1.07249 9.09573 0.89826 8.66487 0.898438H7.61987C7.19776 0.898433 6.79305 1.06664 6.49528 1.36583C6.19752 1.66502 6.03126 2.07053 6.03329 2.49264V2.49264C6.02078 3.36415 5.31068 4.06405 4.43909 4.06396C4.17046 4.06117 3.90733 3.98749 3.67631 3.85038V3.85038C2.91367 3.41339 1.94121 3.67612 1.5024 4.43772L0.945574 5.35305C0.507293 6.1137 0.766452 7.08555 1.52528 7.52697V7.52697C2.01854 7.81174 2.32239 8.33803 2.32239 8.90759C2.32239 9.47715 2.01854 10.0034 1.52528 10.2882V10.2882C0.767415 10.7267 0.507974 11.6961 0.945574 12.4545V12.4545L1.47189 13.3622C1.67749 13.7332 2.02245 14.007 2.43045 14.1229C2.83844 14.2389 3.27582 14.1875 3.6458 13.9801V13.9801C4.00952 13.7678 4.44294 13.7097 4.84973 13.8185C5.25653 13.9274 5.60298 14.1942 5.81209 14.5598C5.9492 14.7908 6.02288 15.0539 6.02567 15.3225V15.3225C6.02567 16.203 6.73941 16.9167 7.61987 16.9167H8.66487C9.54236 16.9168 10.2549 16.2076 10.2591 15.3302V15.3302C10.257 14.9067 10.4243 14.5001 10.7238 14.2006C11.0232 13.9012 11.4298 13.7339 11.8533 13.736C12.1213 13.7431 12.3833 13.8165 12.6161 13.9495V13.9495C13.3767 14.3878 14.3485 14.1287 14.79 13.3698V13.3698L15.3392 12.4545C15.5517 12.0896 15.6101 11.655 15.5013 11.247C15.3925 10.839 15.1255 10.4912 14.7595 10.2806V10.2806C14.3934 10.07 14.1264 9.72219 14.0176 9.31416C13.9088 8.90613 13.9672 8.47156 14.1797 8.10668C14.318 7.86532 14.5181 7.66521 14.7595 7.52697V7.52697C15.5137 7.08579 15.7723 6.11962 15.3392 5.36068V5.36068V5.35305Z" :stroke="settingsPath.includes(path) || tour.start && tour.stage === 5 ?  'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
            <ellipse cx="8.14602" cy="8.90773" rx="2.1968" ry="2.1968" :stroke="settingsPath.includes(path) || tour.start && tour.stage === 5 ? 'white' : '#A4A6B3'" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p class="xl:block text-sm ml-2.5 min-w-24" :class="[[showFullLink ? '' : 'hidden'], [settingsPath.includes(path) || tour.start && tour.stage === 5 ? 'text-white' : 'text-drawer-text']]">Settings</p>
        </router-link>
      </div>
    </div>
    
    <tour
      v-if="tour.start"
      :outerClass="['xl:left-64', [showLink ? 'left-55' : 'left-24']]"
      :innerClass="[
        tour.stage === 1 ? [tour.stage === 1 ? 'top-64 xl:top-46' : ''] : '',
        tour.stage === 2 ? [tour.stage === 2 ? 'top-87.5 xl:top-70' : ''] : '',
        tour.stage === 3 ? [[tour.stage === 3 ? 'xl:bottom-0' : ''], [showLink ? 'bottom-0' : 'bottom-0']] : '',
      ]"
      :title="tour.title"
      :description="tour.description"
      @back="back(tour.stage)"
      @next="next(tour.stage)"
    />
    <!-- <tour
      v-if="tour.start"
      :outerClass="['xl:left-64', [showLink ? 'left-55' : 'left-24']]"
      :innerClass="[
        tour.stage === 1 ? [tour.stage === 1 ? 'top-64 xl:top-46' : ''] : '',
        tour.stage === 2 ? [tour.stage === 2 ? 'top-87.5 xl:top-70' : ''] : '',
        tour.stage === 3 ? [[tour.stage === 3 ? 'xl:top-85' : ''], [showLink ? 'top-105' : 'top-102']] : '',
        tour.stage === 4 ? [[tour.stage === 4 ? 'xl:top-100' : ''], [showLink ? 'top-119' : 'top-115']] : '',
        tour.stage === 5 ? [[tour.stage === 5 ? 'xl:bottom-0' : ''], [showLink ? 'bottom-0' : 'bottom-0']] : '',
      ]"
      :title="tour.title"
      :description="tour.description"
      @back="back(tour.stage)"
      @next="next(tour.stage)"
    /> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Tour from './Tour.vue'

export default {
  name: 'Drawer',
  components: {
    Tour
  },
  props: {
    showFullLink: Boolean
  },
  computed: {
    path() {
      return this.$route.path
    },
    ...mapState({
      tour: state => state.tour
    }),
    getUser() {
      return JSON.parse(localStorage.getItem('userDetails'))
    },
  },
  mounted(){
   
  },
  watch: {
     path(){
         if(this.collectionPaths.includes(this.path)){
        this.openCollectionDrawer = true
    } else {
      this.openCollectionDrawer = false
      }
        if(this.productPaths.includes(this.path)){
        this.openproductDrawer = true
    } else {
      this.openproductDrawer = false
      }
    }
  },
  data() {
    return {
      profile: {},
      showLink: false,
      openCollectionDrawer: false,
      openproductDrawer: false,
      compliancePaths: ['/compliance/company', '/compliance/contact', '/compliance/documents', '/compliance/review'],
      originationPaths: ['/origination/pending', '/origination/approved', '/origination/rejected'],
      originationDetailsPaths: ['/origination/borrower/info', '/origination/borrower/loan', '/origination/borrower/documents'],
      settingsPath: ['/settings/profile', '/settings/team', '/settings/team/roles', '/settings/api', '/settings/products'],
      verificationPaths: ['/verification/request', '/verification/status'],
      collectionPaths: ['/collection/overview', '/collection/customers', '/collection/customers/add-multiple-customer/upload', '/collection/customers/add-multiple-customer/map', '/collection/customers/add-multiple-customer/finish', '/collection/customers/add-single-customer', '/collection/reports'],
      collectionOverviewPaths: ['/collection/overview'],
      collectionReportsPaths: ['/collection/reports'],
      collectionCustomersPaths: ['/collection/customers', '/collection/customers/add-multiple-customer/upload', '/collection/customers/add-multiple-customer/map', '/collection/customers/add-multiple-customer/finish', '/collection/customers/add-single-customer'],
      productPaths: ['/product/overview', '/product/management', '/product/add-product', '/product/rules', `/product/add-rules/basic`, `/product/add-rules/identity`, `/product/add-rules/crc`, `/product/add-rules/disbursement`, `/product/add-rules/collection`, `/product/add-rules/finish`],
      productOverviewPaths: ['/product/overview'],
      productRulesPaths: ['/product/rules', `/product/add-rules/basic`, `/product/add-rules/identity`, `/product/add-rules/crc`, `/product/add-rules/disbursement`, `/product/add-rules/collection`, `/product/add-rules/finish`],
      productManagementPaths: ['/product/management', '/product/add-product'],
      tourGuide: [
        {
          title: 'Home',
          description: 'Get an overview of your loan origination process. View in-depth analysis of top perfoming loan products.'
        },
        {
          title: 'Origination',
          description: 'View leads, select the ones that fit your criteria and proceed to reviewing applications.'
        },
        // {
        //   title: 'Reports',
        //   description: 'View metrics and figures to see how your loan products are performing.'
        // },
        // {
        //   title: 'Billings',
        //   description: 'See how much you have left in your wallet, top up to continue receiving leads.'
        // },
        // {
        //   title: 'Activity log & Settings',
        //   description: 'Manage your account settings and view activity from here.'
        // },
        {
          title: 'Settings',
          description: 'Manage your account settings and activity from here.'
        },
      ]
    }
  },
  methods: {
    collectionDrawer(){
      this.$router.push('/collection/overview?page=1&limit=10')
      this.openCollectionDrawer = true
    },
    productDrawer(){
      this.$router.push('/product/overview?filter=last7days&product=All Product&id=allProduct')
      this.openproductDrawer = true
    },
    showFullDrawer() {
      this.$emit('showFullDrawer')
      this.showLink = !this.showLink
    },
    back(stage) { 
      if (this.tour.stage <= 5) {
        this.$store.commit('SET_TOUR', {
          ...this.tour,
          stage: stage === 1 ? stage : stage -= 1,
          title: this.tourGuide[stage - 1].title,
          description: this.tourGuide[stage - 1].description
        })
      }
    },
    next(stage) {
      if (this.tour.stage <= 3) {
        this.$store.commit('SET_TOUR', {
          start: stage === 3 ? false : true,
          done: stage === 3 ? true : false,
          stage: stage < 3 ? stage += 1 : stage,
          title: this.tourGuide[stage - 1].title,
          description: this.tourGuide[stage - 1].description
        })
      }
    },
  }
}
</script>

<style scoped>
.drawer-scroll::-webkit-scrollbar {
  display: none;
}
</style>