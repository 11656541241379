import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/tailwind.css"
import "./assets/css/style.css"
import Notie from "notie";
import "notie/dist/notie.min.css";
import VueHead from "vue-head";
import JsonCSV from 'vue-json-csv'
import AOS from 'aos';
import 'aos/dist/aos.css';

Vue.config.productionTip = false

Vue.prototype.$notie = Notie;

Vue.use(VueHead)
Vue.use(AOS.init())

Vue.component('downloadCsv', JsonCSV)

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
