<template>
  <div id="app">
    <div @click="smallView = false" v-if="smallView" :class="showFull ? 'left-40' : 'left-24.5'" class="py-3 px-5 fixed top-18 right-0 bg-primary text-white lg:hidden text-center text-sm">
      Switch to a large screen for a better experience. Click to clear.
    </div>
    <div v-if="!externalRoutes.includes($route.name)">
      <navbar :wrapperClass="showFull ? 'left-40' : 'left-24.5'" />
      <drawer @showFullDrawer="showDrawer" :showFullLink="showFull" />
    </div>
    <div :class="!externalRoutes.includes($route.name) ? [showFull ? 'ml-40 xl:ml-64' : 'ml-24.5 xl:ml-64 mt-18'] : ''">
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
    </div>
  </div>
</template>

<script>
import Drawer from './components/shared/Drawer.vue'
import Navbar from './components/shared/Navbar.vue'

export default {
  name: 'App',
  components: {
    Drawer,
    Navbar
  },
  mounted() {
    if(['/signup', '/login', '/team/join', '/origination-landing', '/disbursement-landing', '/collection-landing'].includes(this.path)) {
      this.auth = false
    }
    if(['/origination-landing', '/disbursement-landing', '/collection-landing', '/'].includes(this.path)) {
      this.smallView = false
    }
  },
  computed: {
    path() {
      return this.$route.path
    },
  },
  data() {
    return {
      // path: window.location.pathname,
      smallView: true,
      auth: true,
      showFull: false,
      externalRoutes: ['Signup', 'Login', 'Join Team', '404', 'Forgot Password', 'Reset Password', 'Landing', 'Origination Landing', 'Disbursement Landing', 'Collection Landing', 'Data Privacy', 'Terms of Use', 'Data Protection', 'About Us']
    }
  },
  methods: {
    showDrawer() {
      this.showFull = !this.showFull
    }
  }
}
</script>

<style>
@import url("/src/assets/css/scrollbars.css");
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
  /* transition: opacity 0.8s, transform 0.8s; */
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  /* transform: translateX(-80%); */
}
#app::-webkit-scrollbar {
  display: none;
}
</style>
