export const BASE_API = process.env.VUE_APP_BASE_API;
export const APP_URL = process.env.VUE_APP_APP_URL;

export const AUTH_ROUTES = {
  // SIGNUP: '/signup',
  // LOGIN: '/login',
   SIGNUP: '/signup',
  LOGIN: '/login',
  JOIN_TEAM: '/team/join/:lenderId/:userId/:token',
  FORGOT_PASSWORD: '/password/forgot',
  RESET_PASSWORD: '/password/reset/:id/:token'
}

export const DASHBOARD_ROUTES = {
  GET_STARTED: '/get-started',
  COMPLIANCE: '/compliance/:step',
  HOME: '/dashboard',
  ORIGINATION: {
    BASE: '/origination/:tab',
    BORROWER: '/origination/borrower/:select'
  },
  COLLECTION: {
    OVERVIEW: {
      BASE: '/collection/overview',
    },
    CUSTOMERS: {
      BASE: '/collection/customers',
      SINGLE: '/collection/customers/add-single-customer',
      MULTIPLE: '/collection/customers/add-multiple-customer/:step'
    },
    REPORTS: {
      BASE: '/collection/reports',
    }
  },
  PRODUCT: {
    OVERVIEW: {
      BASE: '/product/overview',
    },
    MANAGEMENT: {
      BASE: '/product/management',
      ADDPRODUCT: '/product/add-product'
    },
    RULES: {
      BASE: '/product/rules',
      ADDPRODUCTRULE: '/product/add-rules/:tab'
    },
  },
  REPORTS: '/reports',
  BILLINGS: {
    BASE: '/billings',
    PRICING: '/billings/pricing'
  },
  ACTIVITY: '/activity',
  SETTINGS: '/settings/:type',
  MANAGE_ROLES: '/settings/team/roles',
  SUPPORT: '/support',
  VERIFICATION: {
    BASE: '/verification/:mode',
    BORROWER: '/verification/borrower/:id'
  }
}